<template>
  <div class="d-flex align-items-end gap-6">
    <CustomInput
      v-model.trim="search"
      class="w-35"
      placeholder="Search" />
    <CustomSelect
      class="w-35"
      v-model="daysCovered"
      label="Days covered"
      :values="daysCoveredOptions" />
    <button class="btn btn-pretender px-0 border-0 gap-2"
      :class="{'text-muted': !anyFiltersSelected}"
      :disabled="!anyFiltersSelected"
      @click="clearFilters">
      <i class="far fa-eraser" aria-hidden="true" />
      Clear filters
    </button>
  </div>
</template>
<script>
import CustomInput from 'vue_widgets/components/custom_input'
import CustomSelect from 'vue_widgets/components/custom_select'

const DEFAULT_DAYS_COVERED = 7

export default {
  components: {
    CustomInput,
    CustomSelect
  },
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    search: '',
    daysCovered: DEFAULT_DAYS_COVERED
  }),
  created() {
    const pluralize = (string, num) => {
      if (num === 1) return string
      return `${string}s`
    }

    this.daysCoveredOptions = Array(10).fill(0).map((_, index) => ({
      value: index + 1,
      text: `${index + 1} ${pluralize('Day', index + 1)}`
    }))

    this.defaultFilters = {
      daysCovered: DEFAULT_DAYS_COVERED
    }

    const savedDaysCovered = localStorage.getItem('savedDaysCovered')
    if (savedDaysCovered) {
      this.daysCovered = parseInt(savedDaysCovered, 10)
    }
  },
  methods: {
    clearFilters() {
      this.search = ''
      this.daysCovered = this.defaultFilters.daysCovered
    }
  },
  computed: {
    selectedFilters() {
      const { search, daysCovered } = this
      return {
        search,
        daysCovered
      }
    },
    anyFiltersSelected() {
      return Object.keys(this.selectedFilters).find((key) => Boolean(this.selectedFilters[key]) && this.defaultFilters[key] !== this.selectedFilters[key])
    }
  },
  watch: {
    selectedFilters: {
      handler() {
        this.$emit('input', this.selectedFilters)
      },
      deep: true,
      immediate: true
    },
    daysCovered() {
      localStorage.setItem('savedDaysCovered', this.daysCovered)
    }
  }
}

</script>
