<template>
  <div class="moments-dashboard">
    <DashboardFilter
      v-model="filters"
      class="pb-4" />
    <VueSlimTable
      ref="table"
      :source="getBusinesses"
      :columns="columns"
      class="table table-fixed mv-businesses-table mv-businesses-table--dashboard">
      <template v-for="column in columns" #[`head:${column.key}`]>
        <span class="text-break-after-each text-dark align-bottom" :key="column.key">
          {{ column.title }}
        </span>
      </template>
      <template #row="{ row }">
        <tr class="text-dark">
          <td class="moments-dashboard__cell">
            <div class="d-flex align-items-center gap-2">
              <template v-if="row.disconnected">
                <i :id="row.id" class="color-warning far fa-exclamation-circle" />
                <BTooltip :target="row.id" title="Location is disconnected" />
              </template>
              <a :href="getBusinessLink(row.business.id)">{{ row.business.name }}</a>
            </div>
          </td>
          <td class="align-top moments-dashboard__cell">
            <span class="moments-dashboard__tag">
              {{ row.draft_moments }}
            </span>
          </td>
          <td
            v-for="({ key, date, value, notCovered }) in formattedCells(columns.slice(2), row)"
            class="align-top moments-dashboard__cell"
            :class="{ 'text-primary cursor-pointer': value > 0, 'text-disabled': value === 0 }"
            :key="key"
            @click="value > 0 ? openSidebar(date, row.business) : null">
            <span
              class="moments-dashboard__tag"
              :class="{'moments-dashboard__tag--danger': notCovered}">
              {{ value }}
            </span>
          </td>
        </tr>
      </template>
      <template #pagination>
        <span>&nbsp;</span>
      </template>
    </VueSlimTable>
    <DashboardSidebar
      v-if="sidebar.active"
      :active="sidebar.active"
      :params="sidebar.params"
      :business-name="sidebar.businessName"
      :date="sidebar.params.scheduledAt"
      @close="closeSidebar" />
  </div>
</template>

<script>
import DashboardFilter from './filter'
import DashboardSidebar from './sidebar'

export default {
  components: {
    DashboardFilter,
    DashboardSidebar
  },
  data() {
    return {
      sidebar: {
        active: false,
        params: {},
        businessName: ''
      },
      filters: {},
      columns: [],
      searchTimeout: null
    }
  },
  methods: {
    getBusinessLink(id) {
      return `${id}`
    },
    getBusinesses(prms = {}) {
      return axios.get(
        '/gb/ajax/businesses/dashboard',
        {
          params: {
            order: prms.order,
            ...this.backendFilters
          },
          paramsSerializer(json) { return qs.stringify(json, { arrayFormat: 'brackets' }) }
        }
      ).then((res) => {
        this.columns = res.data.columns
        return res.data.data
      })
    },
    formattedCells(dateColumns, row) {
      const cells = []

      const { daysCovered } = this.filters
      let noPostingDays = row.days_without_published_checkins ?? 0

      dateColumns.forEach(({ key }) => {
        const cell = {
          date: key,
          key: `${row.business.id}${key}`,
          value: row[key]
        }

        if (cell.value === 0) {
          noPostingDays++
        } else {
          noPostingDays = 0
        }

        if (noPostingDays > daysCovered) {
          cell.notCovered = true
        }

        cells.push(cell)
      })

      return cells
    },
    openSidebar(date, business) {
      const params = {
        business: [business?.id],
        scheduledAt: date
      }

      this.sidebar.active = true
      this.sidebar.params = params
      this.sidebar.businessName = business.name
    },
    closeSidebar() {
      this.sidebar.active = false
      this.sidebar.params = {}
      this.sidebar.businessName = ''
    }
  },
  computed: {
    backendFilters() {
      // eslint-disable-next-line no-unused-vars
      const { daysCovered, ...filters } = this.filters
      return filters
    }
  },
  watch: {
    backendFilters: {
      handler(old, newv) {
        if (JSON.stringify(old) === JSON.stringify(newv)) return
        clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          this.$refs.table.reload()
        }, 500)
      },
      deep: true
    }
  }
}
</script>
