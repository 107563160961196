<template>
  <div class="moment-images-control">
    <div class="moment-images-control__heading">
      <span class="h5 m-0 color-dark font-weight-semi-bold">Image</span>
      <span v-if="images.length" class="font-size-12 text-mute">{{ counterText }}</span>
      <div v-if="images.length && images.length < MAX_FILES" :class="['btn btn-xs btn-primary gap-2 position-relative', { disabled }]">
        <input type="file"
          ref="file"
          class="moment-images-control__add-btn"
          multiple
          :accept="acceptTypes"
          :disabled="disabled"
          @change="onFileChange">
        <i class="fa-regular fa-image" />
        Add photo
      </div>
    </div>
    <div class="moment-images-control__list">
      <div v-if="!images.length" class="d-flex flex-column align-items-center text-center">
        <p>
          Select a file from your device. You can upload 10 more images.
        </p>
        <div :class="['btn btn-primary gap-2 position-relative mb-4', { disabled }]">
          <input type="file"
            ref="file"
            class="moment-images-control__add-btn"
            multiple
            :accept="acceptTypes"
            :disabled="disabled"
            @change="onFileChange">
          <i class="fa-regular fa-image" />
          Add photo
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-center gap-4">
        <p class="mb-0">
          Uploaded images can be collaged <br>
          to showcase the “Before/After” result.
        </p>
        <img :src="require('mv/collage_explain.svg')" alt="">
      </div>
      <span class="d-block mb-1 font-size-14 font-weight-semi-bold text-primary">Cover image</span>

      <div v-if="collageState.state === COLLAGE_STATES.FINISHED" class="moment-images-control__item cover">
        <div class="moment-images-control__left">
          <div :class="['moment-images-control__collage', collageState.orientation.toLowerCase()]">
            <div class="collage-before">
              <img :src="collageState.imgs[0].url" alt="">
              <span>Before</span>
            </div>
            <div class="collage-after">
              <img :src="collageState.imgs[1].url" alt="">
              <span>After</span>
            </div>
          </div>
        </div>
        <div class="form-field flex-1">
          <textarea v-model="collageState.imgs[0].description" :disabled="disabled" :readonly="disabled" />
        </div>
        <button class="btn btn-outline-danger" type="button" @click.prevent="onResetCollageClicked">Reset</button>
      </div>

      <draggable
        v-if="images.length"
        v-model="internalImages"
        handle=".fa-grip-dots-vertical"
        :disabled="disabled"
        @change="$emit('setImages', images)">
        <ImagesItem
          v-for="(image, index) in filteredInternalImages"
          :key="index"
          :image="image"
          :disabled="disabled"
          :ai-messages="aiMessages"
          :class="{ cover: index === 0 && collageState.state !== COLLAGE_STATES.FINISHED}"
          :collage-state="collageState"
          @updateImage="(fields) => updateImage(image, fields)"
          @removeImage="removeImage(image)" />
      </draggable>
      <div v-else class="moment-images-control__item cover p-4">
        <span class="text-mute font-size-12">The first image uploaded will be used as the cover image.</span>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import { subscribe, removeChannel } from 'vue_widgets/audit_report_configurations/helpers'
import { COLLAGE_STATES, IMAGE_ORIENTATION } from '../helpers'
import ImagesItem from './images_item.vue'

function readImage(file) {
  return new Promise((resolve) => {
    const imgPreview = new Image()

    imgPreview.onload = () => {
      const { naturalWidth: width, naturalHeight: height } = imgPreview
      const orientation = width > height ? IMAGE_ORIENTATION.HORIZONTAL : IMAGE_ORIENTATION.VERTICAL
      resolve({
        file, url: imgPreview.src, collageBefore: false, collageAfter: false, orientation
      })
    }

    imgPreview.src = URL.createObjectURL(file)
  })
}

export default {
  components: { draggable, ImagesItem },
  props: {
    images: { type: Array, required: true },
    acceptTypes: { type: String, default: 'image/png, image/jpg, image/jpeg' },
    disabled: { type: Boolean, default: false }
  },
  data() {
    return {
      aiMessages: []
    }
  },
  created() {
    this.MAX_FILES = 10
    this.IMAGE_ORIENTATION = IMAGE_ORIENTATION
    this.COLLAGE_STATES = COLLAGE_STATES

    this.channel = subscribe('DescribeImageChannel', (msg) => {
      this.aiMessages.push(msg)
    })
  },
  beforeDestroy() {
    removeChannel(this.channel)
  },
  computed: {
    internalImages: {
      get() {
        return this.images
      },
      set(val) {
        this.$emit('setImages', val)
      }
    },
    filteredInternalImages() {
      return this.internalImages.filter((image) => !(this.collageState.state === this.COLLAGE_STATES.FINISHED && this.collageState.imgs.includes(image)))
    },
    counterText() {
      const count = this.MAX_FILES - this.images.length
      return `You can upload ${count} more image${count !== 1 ? 's' : ''}.`
    },
    collageState() {
      let beforeSelected
      let afterSelected

      this.images.forEach((img) => {
        if (img.collageBefore) {
          beforeSelected = img
        } else if (img.collageAfter) {
          afterSelected = img
        }
      })

      if (beforeSelected && afterSelected) {
        return {
          state: this.COLLAGE_STATES.FINISHED,
          orientation: beforeSelected.orientation,
          imgs: [beforeSelected, afterSelected]
        }
      } if (beforeSelected) {
        return {
          state: this.COLLAGE_STATES.BEFORE_SELECTED,
          orientation: beforeSelected.orientation,
          imgs: [beforeSelected, null]
        }
      } if (afterSelected) {
        return {
          state: this.COLLAGE_STATES.AFTER_SELECTED,
          orientation: afterSelected.orientation,
          imgs: [null, afterSelected]
        }
      }

      return {
        state: this.COLLAGE_STATES.INITIAL,
        orientation: null,
        imgs: [null, null]
      }
    }
  },
  methods: {
    onFileChange(e) {
      const files = e.target.files || e.dataTransfer.files

      if (!files.length) {
        return
      }

      const wrongFormatFiles = []
      const filteredFiles = Array.from(files).filter(({ name, type }) => {
        if (this.acceptTypes.includes(type)) return true

        wrongFormatFiles.push(name)
        return false
      }).slice(0, this.MAX_FILES - this.images.length)

      if (wrongFormatFiles.length > 0) {
        toastr.warning(`Can't upload files:<br>${wrongFormatFiles.join('<br>')}`, { timeOut: 2000 })
      }

      this.$refs.file.value = ''
      if (!/safari/i.test(navigator.userAgent)) {
        this.$refs.file.type = ''
        this.$refs.file.type = 'file'
      }

      this.createImage(filteredFiles)
    },
    async createImage(files) {
      const newImages = await Promise.all(files.map((file) => readImage(file)))
      this.$emit('setImages', [...this.images, ...newImages])
    },
    updateImage(image, attrs) {
      this.internalImages = this.internalImages.map((img) => {
        if (img === image) {
          return { ...image, ...attrs }
        }

        return img
      })
    },
    removeImage(image) {
      this.$emit('setImages', this.internalImages.filter((img) => img !== image))
    },
    onResetCollageClicked() {
      this.collageState.imgs.forEach((img) => {
        img.collageBefore = false
        img.collageAfter = false
      })
    }
  }
}
</script>
