<template>
  <div class="py-3">
    <p class="w-50">
      Here you can see the amount of moments posted for each category.
      You can click on numbers to see moments for the selected date and status.
    </p>
    <div class="align-items-end d-flex">
      <div class="form-field">
        <label class="m-0">By date</label>
        <DatePicker
          class="w-100"
          mode="range"
          max-date="today"
          v-model="byPeriod" />
      </div>
      <button
        v-if="byPeriod.length"
        class="btn btn-link btn--rem gap-3"
        @click="byPeriod = []">
        <i class="far fa-eraser" /><span>Clear selection</span>
      </button>
    </div>
    <p class="text-center pt-4" v-if="byPeriod.length === 0">
      Showing moments posting statistics for all time
    </p>
    <table class="table mv-businesses-table mt-3">
      <thead>
        <tr>
          <th colspan="1" class="w-20 h-auto p-2 border-transparent" />
          <th colspan="5" class="h-auto p-2 text-center">Moments</th>
        </tr>
        <tr>
          <th
            v-for="(column, index) in columns"
            :class="{'w-20': index === 0, 'text-center': index > 0}"
            :key="column.title">
            {{ column.title }}
          </th>
        </tr>
      </thead>
      <tbody>
        <ReportingTableRow v-for="category in reportingStats"
          class="text-center"
          :by-period="byPeriod"
          :key="category.title"
          :row="category" />
      </tbody>
    </table>
  </div>
</template>
<script>
import axios from 'axios'
import snakecaseKeys from 'snakecase-keys'
import axiosTransform from 'common/axios'
import { DEFAULT_ERROR_MESSAGE } from 'common/constants'
import DatePicker from 'vue_widgets/components/datepicker'
import moment from 'moment'
import ReportingTableRow from './reporting/table_row.vue'

export default {
  components: {
    ReportingTableRow, DatePicker
  },
  props: {
    reportingSourcePath: {
      type: String,
      required: true
    }
  },
  data: () => ({
    byPeriod: [],
    reportingStats: []
  }),
  created() {
    this.columns = [
      { key: 'title', title: 'Category name' },
      { key: 'pendingCount', title: 'Pending' },
      { key: 'approvedCount', title: 'Approved' },
      { key: 'rejectedCount', title: 'Rejected' },
      { key: 'totalCount', title: 'Total' }
    ]

    const daysAgo = (n) => {
      const d = new Date()
      d.setDate(d.getDate() - Math.abs(n))
      return d
    }
    const DATE_MASK = 'MM/DD/YYYY'

    const to = moment(new Date()).format(DATE_MASK)
    const from = moment(daysAgo(30)).format(DATE_MASK)

    this.byPeriod = [from, to]
  },
  computed: {
    filters() {
      return {
        byPeriod: this.byPeriod
      }
    }
  },
  mounted() {
    this.fetchReportingStats()
  },
  methods: {
    async fetchReportingStats() {
      try {
        const response = await axios.get(this.reportingSourcePath, {
          params: snakecaseKeys(this.filters),
          ...axiosTransform
        })
        this.reportingStats = response.data
      } catch {
        toastr.error(DEFAULT_ERROR_MESSAGE)
      }
    }
  },
  watch: {
    filters: {
      handler() {
        this.fetchReportingStats()
      },
      deep: true
    }
  }
}
</script>
