<template>
  <div class="moments-sidebar" :class="{ active: mutableActive }">
    <div class="moments-sidebar__top">
      <div class="moments-sidebar__head">
        <div class="moments-sidebar__name">{{ businessName }}</div>
        <button class="moments-sidebar__close" @click="closeSidebar">Close <i class="fa-regular fa-xmark" /></button>
      </div>
      <div v-if="date" class="moments-sidebar__date">{{ date }}</div>
    </div>
    <div class="moments-sidebar__list scroll ver">
      <div class="moments-sidebar__wrapper">
        <MomentElement
          v-for="moment in fetchedMoments"
          class="moments-sidebar__card"
          :key="moment.obfuscatedId"
          :checkin="moment"
          :allow-actions="false"
          @openPreview="openPreview" />
      </div>
    </div>
    <PreviewImages v-if="mediaIndex !== null"
      :media="mediaSet"
      :current-media-index="mediaIndex"
      @close="closeImage" />
  </div>
</template>

<script>
import snakeCaseKeys from 'snakecase-keys'
import axiosTransform from 'common/axios'

import MomentElement from '../checkins/moment_element.vue'
import PreviewImages from '../../gallery/preview_images'

import { BASE_AJAX_URL } from '../checkins/helpers.js'

export default {
  components: {
    MomentElement,
    PreviewImages
  },
  props: {
    active: {
      type: Boolean,
      required: true
    },
    businessName: {
      type: String,
      required: true
    },
    date: {
      type: String,
      required: true
    },
    params: {
      type: Object,
      required: false
    }
  },
  data: () => ({
    mutableActive: false,
    fetchedMoments: [],
    mediaIndex: null,
    mediaSet: null
  }),
  methods: {
    getMoments() {
      this.isRequestSent = true
      return axios.get(
        BASE_AJAX_URL,
        {
          params: {
            // honestly i doubt we will ever need to paginate this at all
            ...this.params,
            page: 1,
            perPage: 100
          },
          paramsSerializer(json) {
            return qs.stringify(snakeCaseKeys(json, { deep: true }), { arrayFormat: 'brackets' })
          },
          ...axiosTransform
        }
      ).then((res) => {
        this.fetchedMoments = res.data
      }).finally(() => {
        this.isRequestSent = false
      })
    },
    closeSidebar() {
      this.mutableActive = false
      setTimeout(() => {
        this.$emit('close')
      }, 700)
    },
    openPreview({ mediaSet, mediaIndex }) {
      this.mediaSet = mediaSet
      this.mediaIndex = mediaIndex
    },
    closeImage() {
      this.mediaSet = null
      this.mediaIndex = null
    }
  },
  mounted() {
    this.getMoments()
  },
  watch: {
    active: {
      handler(newVal) {
        if (newVal) {
          setTimeout(() => {
            this.mutableActive = true
          })
        }
      },
      immediate: true
    }
  }
}
</script>
