import { DEFAULT_ERROR_MESSAGE } from '../../../common/constants'

export const BASE_URL = '/gb/moments/'

export const BASE_AJAX_URL = '/gb/ajax/moments/'

export const MAX_FILES = 10

export const COLLAGE_STATES = {
  INITIAL: 'INITIAL',
  BEFORE_SELECTED: 'BEFORE_SELECTED',
  AFTER_SELECTED: 'AFTER_SELECTED',
  FINISHED: 'FINISHED'
}

export const IMAGE_ORIENTATION = {
  VERTICAL: 'VERTICAL',
  HORIZONTAL: 'HORIZONTAL'
}

export function handleError(error) {
  let errMessages = [DEFAULT_ERROR_MESSAGE]
  if (error.response.status === 422) {
    errMessages = error.response.data.errors
  }
  if (error.response.status === 413) {
    errMessages = ['Images size exceeds limits.']
  }

  errMessages.forEach((errorText) => {
    toastr.error(errorText)
  })
}

export const FB_STATUSES = {
  skipped: 'Skipped',
  scheduled: 'Pending',
  published: 'Delivered',
  error: 'Error'
}
