<template>
  <tr>
    <td class="text-left text-break w-20">
      <a>{{ row.title }}</a>
    </td>
    <td class="text-primary w-8">
      <a
        :href="buildUrl({ status: 'pending' })"
        target="_blank">
        {{ row.pendingCount }}
      </a>
    </td>
    <td class="text-primary w-8">
      <a
        :href="buildUrl({ status: 'approved' })"
        target="_blank">
        {{ row.approvedCount }}
      </a>
    </td>
    <td class="text-primary w-8">
      <a
        :href="buildUrl({ status: 'rejected' })"
        target="_blank">
        {{ row.rejectedCount }}
      </a>
    </td>
    <td class="text-primary w-8">
      <a
        :href="buildUrl()"
        target="_blank">
        {{ row.totalCount }}
      </a>
    </td>
  </tr>
</template>

<script>
const ALL_MOMENTS_PATH = '/gb/moments'

export default {
  props: {
    row: { type: Object, required: true },
    byPeriod: { type: Array, required: false, default: null }
  },
  created() {
    this.allMomentsPath = ALL_MOMENTS_PATH
  },
  methods: {
    buildUrl({ status } = {}) {
      let url = `${this.allMomentsPath}?categories[]=${this.row.title}`
      if (status) {
        url += `&status=${status}`
      }
      if (this.byPeriod?.length) {
        const [start, end] = this.byPeriod
        url += `&by_period[]=${start}&by_period[]=${end}`
      }
      return url
    }
  }
}
</script>
